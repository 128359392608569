<template>
    <el-card class="box-card">
        <div slot="header">
            <h3 class="inBlock">虚拟用户</h3>
        </div>
        <div class="box">
            <div class="top">
                <el-button
                type="info"
                size="mini"
                icon="el-icon-plus"
                plain
                @click="dialogaAdduser = true"
                >添加虚拟用户</el-button
                >
                
            </div>
        </div>

        <el-table
            :data="virtualuserdata"
            stripe
            border
            :header-row-style="{ height: '50px' }"
            style="width: 100%"
        >
            <el-table-column prop="id" label="用户ID" width="180"> </el-table-column>
            <el-table-column prop="username" label="用户昵称" width="200"></el-table-column>
            <el-table-column label="用户头像" width="200">
                <div class="listimg" slot-scope="scope">
                    <img :src="imgurl+scope.row.header_url" alt="" />
                </div>
            </el-table-column>
            <el-table-column label="操作">
                <div class="caozuo" slot-scope="scope">
                    <!-- <div class="btnBJ shou" style="margin-right: 8px" @click="compile(scope.row.id)">编辑</div> -->
                    <div class="btnDel shou" style="margin-right: 8px" @click="del(scope.row.id)">删除虚拟用户</div>
                </div>
            </el-table-column>
        </el-table>

        <el-pagination
            @current-change="crtChg"
            :current-page="form.page"
            layout="total, prev, pager, next, jumper"
            :total="total"
            >
        </el-pagination>

        <!-- 弹窗 -->
        <el-dialog
            :title="title"
            :visible.sync="dialogaAdduser"
            width="30%"
        >
            <el-form :model="formdata" ref="formdata" label-width="100px" class="demo-ruleForm">
                <el-form-item
                label="添加虚拟用户">
                    <el-input placeholder="请输入昵称" type="text" v-model="formdata.username"></el-input>
                </el-form-item>
                <el-form-item class="el-form-item" label="头像">
                    <div class="imagelist">
                        <template v-if="pic_list.length >= 1">
                            <div
                                v-for="(item, index) in pic_list"
                                :key="index"
                                class="image">
                                <img style="width: 178px;" :src="imgurl+item" alt="" />
                                <span class="shou" @click="delImgList(index)">×</span>
                            </div>
                        </template>
                        <up-img
                        v-if="pic_list.length==0"
                        @addimg="upImgList"
                        :type="'shop'"
                        ></up-img>
                    </div>
                </el-form-item>
                <el-form-item>
                <!-- <span slot="footer" class="dialog-footer"> -->
                    <el-button @click="dialogaAdduser = false">取 消</el-button>
                    <el-button type="primary" @click="addVirtualUser">确 定</el-button>
                <!-- </span> -->
                </el-form-item>
            </el-form>
        </el-dialog>

    </el-card>
</template>
<script>
import UpImg from "@/components/upImg.vue";
import {addVirtualUser,findVirtualUserList,delVirtualUserList} from "@/api/api.js";
import {getSystemsettings} from '../../api/getSystemsetting.js'
export default {
    data(){
        return{
            formdata:{
                username:'',
                header_url:''
            },
			imgurl:'',
            form: {
                username: "",
                status: null,
                page: 1, //  页数
            },
            pic_list:[],
            dialogaAdduser:false,
            title:'添加虚拟用户',
            virtualuserdata:[],
            total: 1
        }
    },
    components: { 
        UpImg 
    },
    created(){
        this.findVitualUserList();
    },
    methods:{
        delImgList(index) {
            console.log(index)
            this.pic_list.splice(index, 1);
        },

        upImgList(imgurl) {
			var type=['png', 'jpg', 'jpeg', 'gif', 'bmp','mp4', 'rmvb', 'mkv', 'wmv', 'flv']
			var img=imgurl.split('.')[imgurl.split('.').length-1]
			if(type.indexOf(img)==-1){
				// alert(imgurl)
				this.$message.warning(imgurl);
			}else{
				this.pic_list.push(imgurl);
			}

            console.log(this.pic_list)
        },

        addVirtualUser(){
            let picstr = this.pic_list.toString();
            console.log(picstr)
            this.formdata.header_url = picstr;
            this.$refs["formdata"].validate(async (valid) => {
                if (valid) {
                    const { data } = await addVirtualUser(this.formdata);
                    if (data.code != 200) return this.$message.error(data.data);
                    this.$message.success(data.data);
					this.pic_list=[]
					this.formdata.username=''
					this.findVitualUserList()
                    this.dialogaAdduser = false;
                } else {
                    this.$message.warning("请检查填写内容!");
                    return false;
                }
            });
        },

        async findVitualUserList() {
            const { data } = await findVirtualUserList(this.form);
            if (data.code != 200) return this.$message.error(data.data);
            this.virtualuserdata = data.data.goods_list.data;
            this.total = data.data.goods_list.total;
            // console.log(this.tableData);
        },

        compile(id) {
            this.$router.push({path: "/goods/add", query: {id}})
        },

        del(id) {
            this.$confirm("您真的要删除该用户吗?, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
                const {data} = await delVirtualUserList({id,status})
                if(data.code != 200) return this.$message.error(data.data)
                this.virtualuserdata.forEach(item => {
                    if(item.id == id) {
                        let index = this.virtualuserdata.indexOf(item)
                        console.log(index)
                        this.virtualuserdata.splice(index, 1)
                    }
                })
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消",
                });
            });
        },

        crtChg(e) {
            this.form.page = e;
            this.findVitualUserList();
        },


    },
	mounted(){
		getSystemsettings.then(res=>{
			this.imgurl=res
		})
	},
}
</script>
<style lang="less">
.top{
    margin-bottom: 20px;
}
.listimg{
    img{
        width: 80px;
        height: 80px;
    }
}
</style>